import React from "react";

export const Technologies = ({ data }) => {
    return (
        <div id="team" className="text-center">
            <div className="container">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>Our Expertise</h2>
                    <p>

                    </p>
                </div>
            </div>
            <div id="cardContainer" className="d-flex align-items-center">
                {data
                    ? data.map((card, index) => (
                        <div key={index} className="card">
                            {/* Add your card content here */}
                            <img src={card.img} className="card-img" alt={card.title} style={(card.height && card.width) ? { height: card.height, width: card.width } : {}}/>
                            <div className="card-body">
                                {/* <h5 className="caption">{card.name}</h5> */}
                            </div>
                        </div>
                    ))
                    : 'Loading...'}
                    
            </div>
        </div>
    );
};